<template>
    <div class="sidebar">
        <div class="filter-title">
            <div class="filter-icon"><ion-icon name="filter-outline"></ion-icon></div>
            Filtrare produse
            <div class="filter-icon-cancel" @click="cancelFilter()"><ion-icon name="close-circle-outline"></ion-icon></div>
        </div>
        <div v-for="category in categories" :key="category.id" class="category-section">
        <div class="filter-item" :class="{ 'selected-item': selectedCategories.includes(category) }">
          <input
            type="checkbox"
            :value="category"
            :id="`category-${category.id}`"
            v-model="selectedCategories"
            @change="applyFilter"
          />
          <label :for="`category-${category.id}`">{{ category.titlu_home }}</label>
        </div>
  
        <div v-for="subcategory in category.subcategories" :key="subcategory.id" class="filter-item subcategory-item" :class="{ 'selected-item': selectedSubcategories.includes(subcategory) }">
          <input
            type="checkbox"
            :value="subcategory"
            :id="`subcategory-${subcategory.id}`"
            v-model="selectedSubcategories"
            @change="applyFilter"
          />
          <label :for="`subcategory-${subcategory.id}`">{{ subcategory.titlu }}</label>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  import { mapState } from 'vuex';

  export default {
    data() {
      return {
        selectedCategories: [],
        selectedSubcategories: [],
      };
    },

    computed: {
        ...mapState('general', ['categories'])
    },
  
    props: {
    //   categories: {
    //     type: Array,
    //     required: true,
    //   },
    },

    created() {
        // console.log('ComponentProductsFilter categories', this.categories);
    },
  
    methods: {
      applyFilter() {
        this.$emit("filterApplied", {
          categories: this.selectedCategories.map(cat => cat.id),
          subcategories: this.selectedSubcategories.map(subcat => subcat.id),
        });
      },
      cancelFilter() {
        this.selectedCategories = [];
        this.selectedSubcategories = [];
        this.$emit("filterApplied", {
          categories: this.selectedCategories,
          subcategories: this.selectedSubcategories
        });
      }
    },
  };
  </script>
  
<style scoped lang="scss">
.sidebar {
    width: 100%;
    // height: calc(100% - 24px);
    height: auto;
    padding: 10px;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
    // border: 2px solid rgba($primary, 0.5);
    border-radius: $round-corners;
    transition: border-color 0.3s ease;
}

.filter-title {
    font-size: 20px;
    font-weight: bold;
    display: inline;
    margin: 10px 10px;
}

.filter-icon {
    font-size: 22px;
    float: left;
    margin: 1px 10px 0 10px;
}
.filter-icon-cancel {
    font-size: 22px;
    float: right;
    margin: 2px 10px 0 10px;
    cursor: pointer;
    :hover {
        color:#092357;
    }
}   

.category-section {
    margin: 10px 0px;
}

.filter-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: $round-corners;
    transition: background-color 0.2s;

    &.selected-item {
        background-color: #f0f0f0;
    }

    &.subcategory-item {
        margin-left: 30px;
    }

    input[type="checkbox"] {
        display: none;

        &:checked + label::before {
            background-color: #44d62c;
        }

        &:checked + label::after {
            opacity: 1;
        }
        &:checked ~ .selected-item {
            background-color: #f0f0f0;
        }
    }

    label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            box-sizing: border-box;
            border: 1px solid #aaa;
            background-color: #fff;
            transform: translateY(-50%);
            transition: background-color 0.2s;
        }

        &::after {
            content: "";
            position: absolute;
            left: 3px;
            top: 40%;
            width: 7px;
            height: 12px;
            border: solid #092357;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) translateY(-50%);
            opacity: 0;
            transition: opacity 0.2s;
        }
    }
}
</style>