<template>
	<main>
		<section
			class="section section--larger"
			v-if="products && products.length > 0"
		>
			<div class="container">
				<h2 class="section__title has-text-centered">
					Produse
				</h2>
				<div class="columns">
					<div class="column is-4-tablet is-3-desktop">
						<component-products-filter @filterApplied="applyProductsFilter"></component-products-filter>
					</div>
					<div class="column is-8-tablet is-9-desktop">
						<div class="columns is-multiline">
							<div
								class=" column is-6-tablet is-4-desktop mb-5"
								v-for="product in filteredAndOrderedByPositionProducts"
								:key="product.id"
							>
								<component-product
									fromPage="home"
									:product="product"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<component-certificates
			:certificates="certificates"
		></component-certificates>
	</main>
</template>

<script>
	import ComponentProduct from '@/components/ComponentProduct.vue';
	import ComponentCertificates from '@/components/ComponentCertificates.vue';
	import ComponentProductsFilter from '@/components/ComponentProductsFilter.vue';

	import { mapState } from 'vuex';

	export default {
		metaInfo() {
			return {
				title: `Aloe Vera Products - Produse`,
			};
		},
		components: {
			ComponentProduct,
			ComponentCertificates,
			ComponentProductsFilter
		},
		mounted() {
			// Trigger prerendering
			if (typeof window !== 'undefined') {
				window.dispatchEvent(new Event('prerender-trigger'));
			}
  		},
		data () {
			return {
				filterCategories: [],
				filterSubcategories: []
			}
		},
		computed: {
			...mapState('general', [
				'products',
				'certificates',
			]),
			// ...mapState('product', [
			// 	'productsList',
			// 	'modalProductsList',
			// 	'modalWithMultipleProducts',
			// ]),
			filteredAndOrderedByPositionProducts() {
				let filteredProducts = this.products.filter(product => {
					// check if any subcategory matches the filterSubcategories array
					const hasMatchingSubcategory = product.subcategories.some(subcategory =>
						this.filterSubcategories.includes(subcategory.id)
					);

					// check if any category matches the filterCategories array
					const hasMatchingCategory = product.categories.some(category =>
						this.filterCategories.includes(category.id)
					);

					// apply the filter conditions based on the rules
					if (this.filterSubcategories.length > 0) {
						// if filterSubcategories array is not empty, prioritize subcategory filter
						return hasMatchingSubcategory;
					} else if (this.filterCategories.length > 0) {
						// if filterSubcategories array is empty but filterCategories array is not,
						// apply category filter only to products that have not been filtered by subcategories
						return hasMatchingCategory && !hasMatchingSubcategory;
					}

					// if both filterSubcategories and filterCategories arrays are empty,
					// return all products
					return true;
				});

				let filteredAndOrderedByPositionProducts = filteredProducts.sort((a, b) => a.pozitie - b.pozitie);

				// console.log('filteredAndOrderedByPositionProducts', filteredAndOrderedByPositionProducts);

				return filteredAndOrderedByPositionProducts;
			},
		},

		methods: {
			applyProductsFilter: function (filterData) {
				// console.log('Page products', this.products);
				this.filterCategories = filterData.categories;
				this.filterSubcategories = filterData.subcategories;
				// console.log('applyProductsFilter', 'categories', this.filterCategories, 'subcategories', this.filterSubcategories);
			}
		}

	};
</script>

<style lang="scss" scoped></style>
